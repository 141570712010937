import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePurchaseList() {
  const { t } = useI18nUtils()
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'order_date', label: 'Дата', sortable: true },
    { key: 'supplier', label: 'Поставщик', sortable: true },
    { key: 'number', label: 'авто', sortable: true },
    { key: 'prodesc', label: 'товар', sortable: true },
    { key: 'quantity', label: 'Пачка', sortable: true },
    {
      key: 'weight', label: 'Вес, т', sortable: true, class: 'text-right'
    },
    {
      key: 'cost', label: 'Сумма', sortable: true, class: 'text-right'
    },
    { key: 'warehouse', label: 'склад', sortable: true },
    { key: 'attachment', label: '#вложение', sortable: true },
    { key: 'status', label: 'статус', sortable: true },
    // formatter: val => `${val}`
    { key: 'comments', label: 'комментарии', sortable: true },
    { key: 'actions' },
  ]

  const queryFields = [
    { label: 'id', whereKeys: ['or.id'], sortKey: 'or.id' },
    { label: 'number', whereKeys: ['ca.number'], sortKey: 'ca.number' },
    { label: 'prodesc', whereKeys: ['od.prodesc'], sortKey: 'od.prodesc' },
    { label: 'attachment', whereKeys: ['or.file_id'], sortKey: 'or.file_id' },
    { label: 'status', whereKeys: ['or.status'], sortKey: 'or.status' },
    {
      label: 'supplier',
      whereKeys: ['sp.firstname', 'sp.surname', 'sp.phone'],
      sortKey: 'sp.firstname',
    },
    {
      label: 'warehouse',
      whereKeys: ['wm.name', 'wm.city', 'wm.address', 'wm.type'],
      sortKey: 'wm.name',
    },
    { label: 'cost', whereKeys: ['or.cost'], sortKey: 'or.cost' },
    { label: 'quantity', whereKeys: ['or.quantity'], sortKey: 'or.quantity' },
    { label: 'weight', whereKeys: ['or.weight'], sortKey: 'or.weight' },
    {
      label: 'order_date',
      whereKeys: ['or.order_date'],
      sortKey: 'or.order_date',
    },
    { label: 'comments', whereKeys: ['or.comments'], sortKey: 'or.comments' },
    // { label: 'id'whereKeys w[hereKeys: ['or.service'], sortKey: 'or.service' },
    // { label: 'dueDate'whereKeys w[hereKeys: ['or.dueDate'], sortKey: 'or.dueDate' },
    {
      label: 'client_id',
      whereKeys: ['or.client_id'],
      sortKey: 'or.client_id',
    },
    { label: 'wm_id', whereKeys: ['or.wm_id'], sortKey: 'or.wm_id' },
    // { label: 'order_name', whereKeys: ['or.order_name'], sortKey: 'or.order_name' },
    // { label: 'firstname', whereKeys: ['sp.firstname'], sortKey: 'sp.firstname' },
    // { label: 'surname', whereKeys: ['sp.surname'], sortKey: 'sp.surname' },
    // { label: 'phone', whereKeys: ['sp.phone'], sortKey: 'sp.phone' },
    // { label: 'name', whereKeys: ['wm.name'], sortKey: 'wm.name' },
    // { label: 'city', whereKeys: ['wm.city'], sortKey: 'wm.city' },
    // { label: 'address', whereKeys: ['wm.address'], sortKey: 'wm.address' },
    // { label: 'type', whereKeys: ['wm.type'], sortKey: 'wm.type' },
  ]

  const perPage = ref(10)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value
      ? refInvoiceListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const q = val => {
    const where = []
    queryFields.forEach(field => {
      field.whereKeys.forEach(column => {
        where.push(`(${column},like,~${val}~)`)
      })
    })

    // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < queryFields.length; i++) {
    //   const columns = queryFields[i].whereKeys
    //   columns.forEach(column => {
    //     where.push(`(${column},like,~${val}~)`)
    //   })
    // }
    return `~and(${where.join('~or')})`
  }

  const fetchPurchases = (ctx, callback) => {
    store
      .dispatch('app-purchase/fetchPurchases', {
        _join: 'or.Orders,_lj,sp.Clients,_lj,wm.Warehouses,_lj,oc.OrdersCars,_j,ca.Cars,_j,od.OrderProductDesc',
        _on1: '(or.client_id,eq,sp.id)',
        _on2: '(or.wm_id,eq,wm.id)',
        _on3: '(or.id,eq,oc.order_id)',
        _on4: '(oc.car_id,eq,ca.id)',
        _on5: '(or.id,eq,od.id)',
        _size: perPage.value,
        _p: currentPage.value,
        _sort:
          (isSortDirDesc.value === true ? '-' : '') + queryFields.filter(item => item.label === sortBy.value)[0].sortKey,
        _where:
          (statusFilter.value !== null
            ? `(or.deletedAt,is,null)~and(or.status,eq,${statusFilter.value})`
            : '(or.deletedAt,is,null)') + (searchQuery.value !== '(or.deletedAt,is,null)~and' ? q(searchQuery.value) : ''),
        _fields:
          'or.id,or.order_date,or.service,or.quantity,or.status,or.cost,or.dueDate,or.comments,or.order_name,or.client_id,or.wm_id,or.weight,or.file_id,or.createdAt,or.updatedAt,sp.firstname,sp.surname,sp.phone,wm.name,wm.city,wm.address,wm.type,oc.car_id,ca.number,od.prodesc',
        _draw: 1,
        _tableconstruction_version: 'vertri',
        _zeTable: 'Orders',
      })
      .then(response => {
        const { data, recordsTotal } = response.data

        callback(data)
        totalInvoices.value = recordsTotal
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'New') return { variant: 'primary', icon: 'PlusCircleIcon' }
    if (status === 'Closed') return { variant: 'success', icon: 'CheckCircleIcon' }
    // if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
    if (status === 'Draft') return { variant: 'warning', icon: 'SaveIcon' }
    // if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    // if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'New') return 'primary'
    // if (status === 'Closed') return 'danger'
    // if (status === 'Downloaded') return 'secondary'
    if (status === 'Draft') return 'warning'
    // if (status === 'Sent') return 'info'
    if (status === 'Closed') return 'success'
    return 'primary'
  }

  const deletePurchase = id => {
    store
      .dispatch('app-purchase/deletePurchase', { id })
      .then(response => {
        if (response.data.code === 1) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          // resetForm()
          refetchData()
        } else {
          // purchaseData.value = response.data
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          // purchaseData.value = undefined
        }
      })
      .finally(() => {
        // asdad
      })
  }

  const updateData = (id, data) => {
    store
      .dispatch('app-purchase/updateData', { id, data })
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Успешно',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Вы успешно нажали на кнопку сохранить.',
          },
        })
        // resetForm()
        // refetchData()
      })
      .catch(error => {
        if (error.response.status === 404) {
          // purchaseData.value = undefined
        }
      })
      .finally(() => {
        // asdad
      })
  }

  return {
    fetchPurchases,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,

    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
    deletePurchase,
    t,
    updateData,
  }
}
