<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ t('Entries') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <!-- <b-button
            v-if="$can('create', 'purchase')"
            variant="primary"
            class="mr-1"
            :to="{ name: 'apps-purchase-add'}"
          >
            {{ t('Add Purchase') }}
          </b-button> -->
          <b-button-group
            v-if="$can('create', 'purchase')"
            class="mr-1"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              variant="primary"
              split
              :text="t('Add Purchase')"
              router-link
              @click="$router.push({ name: 'apps-purchase-add'})"
            >
              <b-dropdown-item
                router-link
                :to="{ name: 'apps-purchase-add-shipment'}"
              >
                {{ t('Add Purchase 2') }}
              </b-dropdown-item>
            </b-dropdown>
          </b-button-group>
          <!-- <b-button
            v-if="$can('create', 'purchase')"
            variant="info"
            :to="{ name: 'apps-purchase-add-shipment'}"
          >
            {{ t('Add Shipment') }}
          </b-button> -->
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="invoice-filter-select"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="fetchPurchases"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      :busy="!fetchPurchases"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner
            class="align-middle mr-1"
          />
          <strong>{{ t('Загрузка') }}...</strong>
        </div>
      </template>

      <template #head(attachment)>
        <feather-icon
          icon="PaperclipIcon"
          class="mr-25"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <b-link
          :to="{ name: 'apps-purchase-preview', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
      </template>

      <template #cell(attachment)="data">
        <b-badge
          v-if="data.item.file_id"
          variant="light-success"
        >
          {{ t('Yes') }}
        </b-badge>
        <b-badge
          v-else
          variant="light-warning"
        >
          {{ t('No') }}
        </b-badge>
      </template>

      <!-- Column: purchase Status -->
      <template #cell(status)="data">
        <!-- <b-avatar
          :id="`invoice-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.status).variant}`"
        >
          <feather-icon
            :icon="resolveInvoiceStatusVariantAndIcon(data.item.status).icon"
          />
        </b-avatar> -->
        <b-badge
          pill
          :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.status).variant}`"
        >
          <feather-icon
            :icon="resolveInvoiceStatusVariantAndIcon(data.item.status).icon"
          />
          {{ t(data.item.status) }}
        </b-badge>
        <!-- <b-tooltip
          :target="`invoice-row-${data.item.id}`"
          placement="top"
        >
          <p class="mb-0">
            {{ data.item.status }}
          </p>
         <p class="mb-0">
            Balance: {{ data.item.balance }}
          </p>
          <p class="mb-0">
            Due Date: {{ data.item.dueDate }}
          </p> -->
        <!-- </b-tooltip>  -->
      </template>

      <!-- Column: Supplier -->
      <template #cell(supplier)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(`${data.item.firstname} ${data.item.surname}`)"
              :variant="`light-${resolveClientAvatarVariant(data.item.status)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ `${data.item.firstname} ${data.item.surname}` }}
          </span>
          <small class="text-muted">{{ data.item.phone }}</small>
        </b-media>
      </template>

      <!-- Column: Warehouse -->
      <template #cell(warehouse)="data">
        <b-media vertical-align="center">
          <!-- <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.name+data.item.type)"
              :variant="`light-${resolveClientAvatarVariant(data.item.status)}`"
            />
          </template> -->
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.name }}
          </span>
          <!-- <small class="text-muted">{{ `${data.item.city} ${data.item.address}` }}</small> -->
        </b-media>
      </template>

      <!-- Column: Warehouse -->
      <template #cell(prodesc)="data">
        <b-media vertical-align="center">
          <small class="fw-light">{{ data.item.prodesc }}</small>
        </b-media>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(order_date)="data">
        <flat-pickr
          v-if="editRowIndex === data.index && selectedCell === 'order_date'"
          v-model="data.value"
          class="form-control"
          @on-change="doSomethingOnChange(data, 'order_date')"
          @on-close="doSomethingOnClose"
        />
        <span
          v-else
          class="text-nowrap"
          @click="editCellHandler(data, 'order_date')"
        >
          {{ changeFormat(data.value) }}
        </span>
      </template>

      <!-- Column: Balance -->
      <template #cell(cost)="data">
        <template class="text-nowrap">
          <span class="text-nowrap">
            {{ roundUpCustom(data.value) }}
          </span>
        </template>
      </template>

      <!-- Column: Balance -->
      <template #cell(weight)="data">
        <template class="text-nowrap">
          <span class="text-nowrap">
            {{ roundUpCustom(data.value, 3) }}
          </span>
        </template>
      </template>

      <template #cell(comments)="data">
        <div class="text-nowrap">
          <span
            :id="`comment-row-${data.item.id}`"
          >
            {{ data.item.comments && data.item.comments.length > 10 ? data.item.comments.slice(0,10)+'..' : data.item.comments }}
          </span>
          <b-tooltip
            :title="data.item.comments"
            :target="`comment-row-${data.item.id}`"
          />
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <!-- <feather-icon
            :id="`invoice-row-${data.item.id}-send-icon`"
            :disabled="data.item.status !== 'Closed'"
            icon="SendIcon"
            class="cursor-pointer"
            size="16"
            @click="$router.push({ name: 'apps-sales-add', params: { id: data.item.id }})"
          />
          <b-tooltip
            :title="t('Sell Purchase')"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-send-icon`"
          /> -->

          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'apps-purchase-preview', params: { id: data.item.id }})"
          />
          <b-tooltip
            :title="t('Preview Purchase')"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item>
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Download</span>
            </b-dropdown-item> -->
            <b-dropdown-item
              :to="{ name: 'apps-purchase-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ t('Edit') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="promptDialog(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ t('Delete') }}</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item>
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">{{ t('Duplicate') }}</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ t('Showing') }} {{ dataMeta.from }} {{ t('to') }} {{ dataMeta.to }} {{ t('of') }} {{ dataMeta.of }} {{ t('entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BSpinner, BButtonGroup, // BFormDatepicker,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import usePurchaseList from './usePurchaseList'

import purchaseStoreModule from '../purchaseStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    // BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,
    BButtonGroup,
    // BFormDatepicker,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  methods: {
    promptDialog(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: false,
        })
        .then(value => {
          if (value) {
            this.deletePurchase(id)
          }
        })
    },
  },
  setup() {
    const PURCHASE_APP_STORE_MODULE_NAME = 'app-purchase'

    // Register module
    if (!store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.registerModule(PURCHASE_APP_STORE_MODULE_NAME, purchaseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.unregisterModule(PURCHASE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'New',
      'Draft',
      'Closed',
    ]

    const {
      fetchPurchases,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      deletePurchase,
      t,
      updateData,
    } = usePurchaseList()

    const editRowIndex = ref(null)
    const selectedCell = ref(null)
    const editCellHandler = (data, name) => {
      editRowIndex.value = data.index
      selectedCell.value = name
    }
    const doSomethingOnClose = () => {
      editRowIndex.value = null
      selectedCell.value = null
    }
    const doSomethingOnChange = (data, name) => {
      console.log('doSomethingOnChange', data, fetchPurchases)
      // eslint-disable-next-line no-param-reassign
      data.item[name] = data.value
      const obj = {}
      obj[name] = data.value
      updateData(data.item.id, obj)
    }
    const changeFormat = date => moment(date).format('YYYY-MM-DD')
    const roundUp = num => {
      const x = num ? (Math.round(num * 10) / 10) : 0
      const parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return parts.join('.')
    }

    function roundUpCustom(givnum, decimals = 2) {
      if (givnum !== null) {
        const num = Number.isNaN(givnum) ? 0 : givnum
        const tem = 10 ** decimals
        const preval = (
          Math.round(
            num * tem + (decimals > 0 ? 1 : 0) * (Math.sign(num) * (10 / (100 ** decimals)))
          ) / tem
        ).toFixed(decimals)
        const parts = preval.toString().split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        return parts.join('.')
      }
      return 0
    }

    return {
      roundUpCustom,
      fetchPurchases,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,

      deletePurchase,
      t,
      editCellHandler,
      selectedCell,
      editRowIndex,
      changeFormat,
      doSomethingOnClose,
      doSomethingOnChange,
      roundUp,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
